import type { FC } from 'react'
import { ButtonSpinner } from '../spinners'

interface GoogleLoginButtonProps {
  text?: string
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}

export const GoogleLoginButton: FC<GoogleLoginButtonProps> = ({
  text = 'Sign in with Google',
  onClick,
  disabled,
  loading,
}) => {
  return (
    <>
      <button
        className="my-1 flex w-full items-center justify-center gap-2 rounded bg-blue-900  py-3     text-white shadow-sm hover:bg-blue-700  hover:shadow-md focus:ring-2 focus:ring-blue-800"
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <ButtonSpinner className="h-6 w-6 animate-spin text-gray-500" />
        ) : (
          <div className="flex h-6 w-6 content-center items-center">
            <svg
              width="21"
              height="20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 10.225c0-.658-.06-1.283-.162-1.892h-9.62v3.759h5.508c-.247 1.233-.97 2.275-2.043 2.983v2.5h3.286c1.924-1.742 3.031-4.308 3.031-7.35Z"
                fill="#4285F4"
              />
              <path
                d="M10.717 20c2.758 0 5.066-.9 6.752-2.425l-3.287-2.5c-.92.6-2.086.967-3.465.967-2.665 0-4.921-1.759-5.73-4.134H1.598v2.575C3.275 17.75 6.724 20 10.717 20Z"
                fill="#34A853"
              />
              <path
                d="M4.987 11.908A5.69 5.69 0 0 1 4.663 10c0-.667.12-1.308.324-1.908V5.517H1.598A9.714 9.714 0 0 0 .5 10c0 1.617.4 3.133 1.098 4.483l3.389-2.575Z"
                fill="#FBBC05"
              />
              <path
                d="M10.717 3.958c1.507 0 2.852.509 3.916 1.5l2.912-2.85C15.783.992 13.475 0 10.717 0 6.724 0 3.275 2.25 1.598 5.517l3.389 2.575c.809-2.375 3.065-4.134 5.73-4.134Z"
                fill="#EA4335"
              />
            </svg>
          </div>
        )}
        <span className="text-sm font-medium">{text}</span>
      </button>
    </>
  )
}
