import { useAuth } from '@hooks/useAuth'
import { useRedirectOrCreateUser } from '@hooks/useRedirectOrCreateUser'
import { XCircleIcon } from '@heroicons/react/solid'
import { useEffect, useRef, FC } from 'react'
import { useForm } from '@mantine/form'
import { GetAuthError } from '@utils/errors'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import {
  useSignInWithGoogle,
  useSignInWithEmailAndPassword,
} from 'react-firebase-hooks/auth'
import { GoogleLoginButton } from '@/components/signin/GoogleLoginButton'
import { FormInput } from '@/components/signin/FormInput'
import { LayoutOnboarding } from '@/components/signin/LayoutOnboarding'
import { TextCenteredLine } from '@/components/signin/TextCenteredLine'
import { FormButton } from '@/components/signin/FormButton'
import { MessageBox } from '@/components/signin/MessageBox'
import { TextColoredLink } from '@/components/signin/TextColoredLink'

interface SignInProps {
  linkInvitationToken?: string
}

export const Signin: FC<SignInProps> = observer(({ linkInvitationToken }) => {
  const { auth } = useAuth()
  const mounted = useRef<boolean>()
  const [
    signInWithEmailAndPassword,
    emailUser,
    isEmailAuthLoading,
    emailAuthError,
  ] = useSignInWithEmailAndPassword(auth)
  const [signInWithGoogle, googleUser, isGoogleAuthLoading, googleAuthError] =
    useSignInWithGoogle(auth)

  const { isLoading: isRedirectLoading } =
    useRedirectOrCreateUser(linkInvitationToken)

  /* Guard if page is navigated away while sign in process is still active */
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  return (
    <LayoutOnboarding meta={{ title: 'Login' }}>
      <TextCenteredLine>
        <span className="my-8 text-2xl font-semibold text-slate-800">
          Login
        </span>
      </TextCenteredLine>

      <GoogleLoginButton
        text="Continue with Google"
        onClick={() => {
          signInWithGoogle([], { prompt: 'select_account' })
        }}
        disabled={isGoogleAuthLoading || isRedirectLoading}
        loading={isGoogleAuthLoading || (isRedirectLoading && !!googleUser)}
      />

      <form
        className="mt-6 flex h-full flex-col gap-y-6"
        onSubmit={form.onSubmit(values =>
          signInWithEmailAndPassword(values.email, values.password),
        )}
      >
        <FormInput
          label="Email"
          id="email"
          type="email"
          autoComplete="email"
          required
          placeholder="Enter your email"
          {...form.getInputProps('email')}
        />

        {/^\S+@\S+$/.test(form.getInputProps('email').value || '') && (
          <FormInput
            label="Password"
            id="password"
            name="password"
            type="password"
            placeholder="Enter your password"
            required
            {...form.getInputProps('password')}
          />
        )}

        <FormButton
          type="submit"
          disabled={isEmailAuthLoading || isRedirectLoading}
          loading={isEmailAuthLoading || (isRedirectLoading && !!emailUser)}
        >
          Continue with Email
        </FormButton>

        <div className="flex flex-row items-center justify-center">
          <div className="text-sm">
            <Link
              href={`/reset-password${
                form.getInputProps('email').value &&
                `?email=${form.getInputProps('email').value}`
              }`}
            >
              <a>
                <TextColoredLink>Forgot your password?</TextColoredLink>
              </a>
            </Link>
          </div>
        </div>

        {emailAuthError?.code === 'auth/user-not-found' ? (
          <MessageBox
            type="error"
            title="Email not registered"
            icon={
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            }
            message={
              <>
                No user found,
                <Link
                  href={`/signup${
                    form.getInputProps('email').value &&
                    `?email=${form.getInputProps('email').value}`
                  }`}
                >
                  {' '}
                  <a className="ml-1">
                    <TextColoredLink>register here</TextColoredLink>!
                  </a>
                </Link>
              </>
            }
          />
        ) : (
          ((googleAuthError &&
            googleAuthError.code !== 'auth/popup-closed-by-user') ||
            emailAuthError) && (
            <MessageBox
              type="error"
              title="Oh snap"
              icon={
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              }
              message={
                googleAuthError?.message ||
                (emailAuthError && GetAuthError(emailAuthError))
              }
            />
          )
        )}

        <p className="flex flex-col justify-center text-center sm:flex-row">
          <span className="mr-1">Need an account?</span>
          <Link href="/signup">
            <a>
              <TextColoredLink>Sign up here</TextColoredLink>
            </a>
          </Link>
        </p>
      </form>
    </LayoutOnboarding>
  )
})

export default Signin
