import { trpc } from '@utils/trpc'
import { useRouter } from 'next/router'
import { useAuth } from '@hooks/useAuth'
import { useEffect } from 'react'

export const useRedirectOrCreateUser = (linkInvitationToken?: string) => {
  const router = useRouter()
  const { user } = useAuth()

  const { mutate: createUserAndOrg, isLoading: isUserCreating } =
    trpc.useMutation(['user.createUserAndOrg'], {
      onSuccess: () => {
        router.push('/signup/connect')
      },
      onError: async error => {
        console.log('signin create.user onError', error)
      },
    })

  const { isFetching: isProductsFetching, refetch } = trpc.useQuery(
    [
      'product.byUID',
      {
        linkInvitationToken: linkInvitationToken || null,
      },
    ],
    {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      context: {
        skipBatch: true,
      },
      enabled: false,
      onSuccess: data => {
        if (
          (data.atsIntegration || data.hrisIntegration) &&
          !data.sourceAndScreen
        ) {
          router.push('/dashboard')
          return
        }

        router.push('/')
      },
      onError: async error => {
        if (error.data?.code === 'UNAUTHORIZED' && user?.email) {
          createUserAndOrg({
            email: user.email,
            name: user.displayName || user.email,
          })
        }
      },
    },
  )

  useEffect(() => {
    if (user && !user.isAnonymous) {
      refetch()
    }
  }, [user])

  return {
    isLoading: isProductsFetching || isUserCreating,
  }
}
