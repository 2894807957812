import { FC } from 'react'

export const TextColoredLink: FC = ({ children, ...props }) => {
  return (
    <span
      {...props}
      className="font-medium text-blue-800 underline outline-blue-800 hover:text-blue-600"
    >
      {children}
    </span>
  )
}
